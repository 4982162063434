<template>
  <div class="get-code">
    <h2>{{ $t(titleKey) }}</h2>
    <p class="text-blue">
      {{ $t(headerTextKey, { toAction: toAction}) }}
    </p>
    <form>
      <div
        v-if="bothChannelsEnabled"
        class="form-check"
      >
        <input
          id="channelEmail"
          v-model="channel"
          :disabled="submitting"
          class="form-check-input"
          type="radio"
          name="channel"
          value="email"
        >
        <label
          class="form-check-label"
          for="channelEmail"
        >{{ $tc("email", 1) }}</label>
      </div>

      <div
        v-if="bothChannelsEnabled"
        class="form-check"
      >
        <input
          id="channelMobile"
          v-model="channel"
          :disabled="submitting"
          class="form-check-input"
          type="radio"
          name="channel"
          value="mobile"
        >
        <label
          class="form-check-label"
          for="channelMobile"
        >{{ $tc("mobileNumber", 1) }}</label>
      </div>

      <div
        v-if="channel == 'email'"
        class="form-group mt-4"
      >
        <label for="email">{{ $t("emailAdress") }}</label>
        <input
          id="email"
          v-model.trim="email"
          v-validate="'required|email'"
          type="email"
          class="form-control"
          name="email"
          @focus="resetManuallySetErrors"
        >
        <span
          v-show="errors.has('email')"
          class="error"
        >{{ errors.first('email') }}</span>
      </div>

      <div
        v-if="channel == 'mobile'"
        class="form-group m-t pt-3"
      >
        <label for="email">{{ $tc("mobileNumber", 1) }}</label>
        <input
          id="mobile"
          v-model="mobile"
          v-validate="'required|min:10|max:10|numeric'"
          type="numeric"
          class="form-control"
          name="mobile"
          @focus="resetManuallySetErrors"
        >
        <span
          v-show="errors.has('mobile')"
          class="error"
        >{{ errors.first('mobile') }}</span>
      </div>
      <div id="errors">
        <span
          v-for="(error, key) in responseErrors"
          :key="key"
          class="error"
        >
          {{ error }}
        </span>
      </div>
      <div
        v-show="channel != ''"
        class="form-group mt-3 pt-3 mb-0 pb-3"
      >
        <button
          :disabled="errors.any() || submitting"
          type="submit"
          class="btn btn-primary d-block w-100 mb-3"
          @click.prevent="onSubmit"
        >
          {{ $t(buttonTextKey) }}
        </button>
        <router-link
          :to="{ name: 'Login' }"
          tag="button"
          class="btn d-block w-100"
        >
          {{ $t('cancel') }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'

const MODE_ACCOUNT_SETUP = 'account-setup'
const MODE_RESET_PASSWORD = 'forgot-password'
const MODE_UPDATE_CONTACT_INFO = 'validate-contact-info'

export default {
  name: 'GetCode',
  mixins: [Helpers],
  props: {
    mode: {
      type: String,
      default: MODE_ACCOUNT_SETUP
    },
    defaultChannel: {
      type: String,
      default: null
    },
    defaultValue: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      channel: '',
      email: '',
      mobile: '',
      submitting: false,
      responseErrors: []
    }
  },
  computed: {
    ...mapGetters({
      settings: 'generalSettings',
      getUUID: 'getAccessCodeUUID',
      accounts: 'accounts'
    }),
    toAction () {
      return this.mode === 'forgot-password' ? ` ${this.$t('andResetYourPassword')}` : ''
    },
    titleKey () {
      return this.mode === 'account-setup' ? 'activateAccount' : 'forgotPassword'
    },
    headerTextKey () {
      return this.bothChannelsEnabled
        ? 'getAccessCodeHeaderBoth'
        : this.smsEnabled ? 'getAccessCodeHeaderMobile' : 'getAccessCodeHeaderEmail'
    },
    buttonTextKey () {
      return this.mode === 'account-setup' ? 'sendAccessCode' : 'sendCode'
    }
  },
  watch: {
    channel () {
      this.resetResponseErrors()
    },
    email () {
      this.resetResponseErrors()
    },
    mobile () {
      this.resetResponseErrors()
    }
  },
  created () {
    this.channel = this.bothChannelsEnabled || this.bothChannelsDisabled || this.emailEnabled ? 'email' : 'mobile'

    if (this.$route.query && this.$route.query.email) {
      this.channel = 'email'
      this.email = this.$getDecodedId(this.$route.query.email)
    }

    if (this.$route.query && this.$route.query.sms) {
      this.channel = 'sms'
      this.sms = this.$getDecodedId(this.$route.query.sms)
    }
  },
  mounted () {
    if (this.defaultChannel === 1) {
      this.channel = 'email'
      this.email = this.defaultValue
      return
    }

    if (this.defaultChannel === 2) {
      this.channel = 'mobile'
      this.mobile = this.defaultValue
      return
    }

    const query = this.$route.query
    if (query && query.channel && query.value) {
      const isEmail = parseInt(query.channel) === 1
      const channelString = isEmail ? 'email' : 'mobile'
      this.channel = channelString
      this[channelString] = this.$getDecodedId(query.value)
    }
  },
  methods: {
    resetResponseErrors () {
      this.responseErrors = []
    },
    doFieldBlur () {
      const email = document.getElementById('email')
      const mobile = document.getElementById('mobile')

      if (email) {
        document.getElementById('email').blur()
      }

      if (mobile) {
        document.getElementById('mobile').blur()
      }
    },
    validateForm () {
      return this.$validator.validate(`${this.channel}`)
    },
    validateFormCallback (formIsValid) {
      return formIsValid ? this.getCode() : false
    },
    setSubmittingState () {
      this.loader = this.$loading.show()
      this.submitting = true
    },
    getRequestData () {
      const data = {
        channel: this.channel === 'email' ? 1 : 2,
        value: this.channel === 'email' ? this.email.toLowerCase() : this.mobile
      }

      if (this.$route.query && this.$route.query.resetPassword === 'true') {
        data.reset_password = true
      }

      if (this.$route.query && this.$route.query.u) {
        data.uuid = this.$getDecodedId(this.$route.query.u)
      }

      if (this.mode === MODE_RESET_PASSWORD) {
        data.forgot_password = true
      } else if (this.mode === MODE_UPDATE_CONTACT_INFO) {
        data.contact_info = true
      }

      return data
    },
    getCode () {
      this.resetResponseErrors()
      this.setSubmittingState()
      this.sendRequest().then(this.requestSuccessCallback).finally(this.unsetSubmittingState)
    },
    unsetSubmittingState () {
      if (this.loader) { this.loader.hide() }
      this.submitting = false
    },
    sendRequest () {
      const data = this.getRequestData()
      return this.$store.dispatch('GET_ACCESS_CODE', data)
    },
    requestSuccessCallback (response) {
      const hasError = response && response.status !== 200
      return hasError ? this.showRequestRequestErrors(response) : this.finalizeRequest()
    },
    finalizeRequest () {
      const data = this.getRequestData()
      data.uuid = this.getUUID
      data.accounts = this.accounts
      this.$emit('submit', data)
    },
    showRequestRequestErrors (response) {
      const fieldErrors = ['channel']
      Object.keys(response.data.errors).forEach((field) => {
        const error = response.data.errors[field]
        const isArray = Array.isArray(error)
        const errorToAdd = isArray ? error[0] : error
        if (fieldErrors.includes(field)) {
          this.$validator.errors.add(field, response.data.errors[field])
        }
        this.responseErrors.push(errorToAdd)
      })
    },
    resetManuallySetErrors () {
      this.$validator.reset()
      this.resetResponseErrors()
    },
    onSubmit () {
      this.doFieldBlur()
      this.validateForm().then(this.validateFormCallback)
    }
  }
}
</script>
