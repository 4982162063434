<template>
  <div class="select-account">
    <h2>{{ $t('whichAccount') }}</h2>
    <p class="help-text">
      {{ $t('accessMultipleAccount') }}
    </p>

    <button
      v-for="(account, index) in accounts"
      :key="index"
      class="btn btn-primary d-block w-100 mb-3"
      type="button"
      @click="onSelectAccount(account)"
    >
      {{ account.phi_safe_name }}
      <span
        v-for="(relation, i) in account.relations"
        :key="i"
        class="text-lowercase"
      >
        ({{ $t(`patient_relations.relation_id_${relation.relation_id}`) }})
      </span>
    </button>

    <button
      class="btn d-block w-100"
      type="button"
      @click="onCancel"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'SelectAccount',
  props: ['accounts'],
  methods: {
    onCancel () {
      this.$emit('cancel')
    },
    onSelectAccount (account) {
      this.$emit('submit', account)
    }
  }
}
</script>
