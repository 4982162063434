<template>
  <div>
    <form @submit.prevent="onSubmit">
      <h2>{{ $t('enterCode') }}</h2>
      <p class="text-blue">
        {{ $t("enterAccessCode") }}: <br>
        <span class="email">{{ value }}</span>
      </p>

      <div class="form-group">
        <masked-input
          id="accessCode"
          v-model="access_code"
          v-validate="'required|min:6|max:6'"
          type="tel"
          autocomplete="off"
          name="accessCode"
          class="form-control text-center"
          :mask="[/\d/, /\d/, /\d/, /\d/,/\d/,/\d/]"
          :guide="true"
          placeholder="______"
          data-vv-as="Access Code"
          placeholder-char="_"
        />
        <span
          v-show="errors.has('accessCode')"
          class="error"
        >{{ errors.first('accessCode') }}</span>
      </div>

      <div class="form-group pt-3">
        <button
          :disabled="errors.any() || submitting"
          type="submit"
          class="btn btn-primary d-block w-100"
          @click.prevent="onSubmit"
        >
          {{ $t("continue") }}
        </button>
        <p class="help-text mt-3">
          {{ $t("needNewCode") }} <a
            href="#"
            @click.prevent="$emit('resend')"
          >{{ $t("clickToTryAgain") }}.</a>
        </p>
      </div>
    </form>
  </div>
</template>

<script>

import MaskedInput from 'vue-text-mask'
import { mapActions } from 'vuex'

const MODE_ACCOUNT_SETUP = 'account-setup'
const MODE_RESET_PASSWORD = 'forgot-password'
const MODE_UPDATE_CONTACT_INFO = 'validate-contact-info'

const DEFAULT_RESPONSE_ERROR = 'There was an error present while validating the Verification Code, Please try again.'

export default {
  name: 'ValidateCode',
  components: { MaskedInput },
  props: {
    /* eslint-disable */
    channel: {},
    value: {
      type: String
    },
    mode: {
      type: String,
      default: MODE_ACCOUNT_SETUP
    },
    uuid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      access_code: '',
      submitting: false
    }
  },
  computed: {
    noUndersCoreAccessCode () {
      return this.access_code.replace(new RegExp('_', 'g'), '') // eslint-disable-line prefer-regex-literals
    },
    channelText () {
      return this.channel === 1 ? this.$tc('email', 2) : this.$tc('phone', 2)
    },
    toAction () {
      return this.mode === 'forgot-password' ? ` ${this.$t('andResetYourPassword')}` : ''
    }
  },
  watch: {
    access_code (newValue) {
      const accesCode = newValue.replace(/_/g, '')
      if (accesCode.length < 6 && this.$validator.errors.items.length) {
        this.$validator.reset('accessCode')
      }
    }
  },
  created () {
    this.$nextTick().then(() => {
      const accessCode = document.getElementById('accessCode')
      accessCode.focus()
    })
  },
  methods: {
    ...mapActions({
      validateAccessCode: 'VALIDATE_ACCESS_CODE'
    }),
    onSubmit () {
      this.validateForm().then(this.validateFormCallback)
    },
    validateForm () {
      return this.$validator.validate('accessCode', this.noUndersCoreAccessCode)
    },
    validateFormCallback (formIsValid) {
      return formIsValid ? this.executeBackendValidation() : false
    },
    executeBackendValidation () {
      this.setSubmittingState()
      const data = this.getValidationRequestData()
      this.validateAccessCode(data).then((response) => {
        return this.handleValidationRequestResponse(response)
      }).finally(() => {
        return this.unsetSubmittingState()
      })
    },
    handleValidationRequestResponse (response) {
      const errorResponse = (response && response.status !== 200)
      return errorResponse ? this.handleResponseError(response) : this.handleResponseSuccess(response)
    },
    handleResponseError (response) {
      this.$emit('setuuid', null)
      if (!response || !response.data || !response.data.errors || !response.data.errors.code) {
        this.$validator.errors.add('accessCode', DEFAULT_RESPONSE_ERROR)
        return true
      }
      this.$validator.errors.add('accessCode', response.data.errors.code)
      return true
    },
    handleResponseSuccess () {
      this.$emit('submit', this.access_code)
      return true
    },
    setSubmittingState () {
      this.loader = this.$loading.show()
      this.submitting = true
    },
    unsetSubmittingState () {
      if (this.loader) { this.loader.hide() }
      this.submitting = false
    },
    getValidationRequestData () {
      const data = {
        channel: this.channel,
        value: this.value,
        code: this.access_code
      }

      if (this.mode === MODE_RESET_PASSWORD || this.mode === MODE_ACCOUNT_SETUP) {
        data.forgot_password = true
      } else if (this.mode === MODE_UPDATE_CONTACT_INFO) {
        data.contact_info = true
        data.uuid = this.uuid
        data.username = this.$store.getters.myAccountData.username
      }

      return data
    }
  }
}
</script>
<style lang="scss" scoped>
  span.error{
    float: none;
  }
  .form-group{
    min-height: 75px;
  }
  #accessCode{
    font-size: 28px;
    border: none;
    letter-spacing: 8px;
    margin-top: 65px;
    background: none;
  }
  #accessCode:focus{
    box-shadow: none;
  }
  .email {
    word-break: break-all;
  }
</style>
